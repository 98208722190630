/**
 * 示例-自定义组件
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">自定义组件示例</div>
        <div class="flex-list">
          <div class="flex-list-item" v-for="(item, index) in list" :key="'item' + index" @click="showDetail(item.name)">
            <p>{{ item.title }}</p>
            <span>{{ item.subTitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [
          {
            title: '业务弹窗',
            subTitle: 'modal-test',
            name: 'Modal'
          },
          {
            title: '图表',
            subTitle: 'sp-chart',
            name: 'Chart'
          },
          {
            title: '城市选择',
            subTitle: 'sp-city',
            name: 'City'
          },
          {
            title: '富文本编辑器',
            subTitle: 'sp-editor',
            name: 'Editor'
          },
          {
            title: '超链接',
            subTitle: 'sp-linkto',
            name: 'Linkto'
          },
          {
            title: '百度地图',
            subTitle: 'sp-map',
            name: 'Map'
          },
          {
            title: '腾讯地图',
            subTitle: 'sp-map-t',
            name: 'MapT'
          },
          {
            title: '数字格式化',
            subTitle: 'sp-number',
            name: 'Number'
          },
          {
            title: '省市区联动',
            subTitle: 'sp-region',
            name: 'Region'
          },
          {
            title: '通用布局容器',
            subTitle: 'sp-row',
            name: 'Row'
          },
          {
            title: '分页表格',
            subTitle: 'sp-table',
            name: 'Table'
          },
          {
            title: '上传',
            subTitle: 'sp-upload',
            name: 'Upload'
          }
        ]
      };
    },
    methods: {
      /**
       * 查看详情
       */
      showDetail(type) {
        this.$router.push({
          name: 'testComponent' + type
        });
      }
    }
  };
</script>